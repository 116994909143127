import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Careers from "./pages/Careers";
import Clients from "./pages/Clients";
import Contact from "./pages/Contact";
import Services from "./pages/Services";
import "../src/pages/css/pages.css";
import FTTXDeployment from "./pages/FTTXDeployment";
import NetworkingIPMPLSCore from "./pages/NetworkingIPMPLSCore";
import NetwrokingSolutions from "./pages/NetwrokingSolutions";
import SupportServices from "./pages/SupportServices";
import ManagedService from "./pages/ManagedService";
import FLM_SLM from "./pages/FLM_SLM";
import DeportMaintenance from "./pages/DeportMaintenance";
import StaffAugumentation from "./pages/StaffAugumentation";
import ServiceTeamStructure from "./pages/ServiceTeamStructure";
import TransportEquipment from "./pages/TransportEquipment";
import StaffingSolutions from "./pages/StaffingSolutions";
import NocServices from "./pages/NocServices";
import OandMservices from "./pages/OandMservices";
import FIveG from "./pages/FIveG";
import Capabilites from "./pages/Capabilites";
import yFibre from './assets/y-fibre.png';
import skyLine from './assets/skyline-logo.png';
import Microwave from "./pages/Microwave";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ServicesDropdown from "./pages/ServicesDropdown";
import ItServices from "./pages/ItServices";
import StaffingServices from "./pages/StaffingServices";



function App() {
  return (
    <HashRouter>

      <p>website is under development / revamping.</p>

      {/* <div className="y-fibre">

        <a href="https://yfibre.com/"><img src={yFibre} alt="y-fibre" width={"100%"} /></a>

      </div>

      <div className="y-fibre sky-line">
        <a href="https://skylineweb.co/"><img src={skyLine} alt="skyline-logo" width={"66%"} /></a>

      </div>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="/services" element={<Services />} />
        <Route path="/services/fttxdeployment" element={<FTTXDeployment />} />
        <Route path="/services/NetworkingIPMPLSCore" element={<NetworkingIPMPLSCore />} />
        <Route path="/services/NetworkingSolutions" element={<NetwrokingSolutions />} />
        <Route path="/services/SupportServices" element={<SupportServices />} />
        <Route path="/services/ManagedService" element={<ManagedService />} />
        <Route path="/services/FLM_SLM" element={<FLM_SLM />} />
        <Route path="/services/DeportMaintenance" element={<DeportMaintenance />} />
        <Route path="/services/StaffAugumentation" element={<StaffAugumentation />} />
        <Route path="/services/ServiceTeamStructure" element={<ServiceTeamStructure />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/transport" element={<TransportEquipment />} />
        <Route path="/staffing_solution" element={<StaffingSolutions />} />
        <Route path="/noc_services" element={<NocServices />} />
        <Route path="/o&m_services" element={<OandMservices />} />
        <Route path="/5G" element={<FIveG />} />
        <Route path="/capabilites" element={<Capabilites />} />
        <Route path="/microwave" element={<Microwave />} />
        <Route path="/Terns&conditions" element={<TermsAndConditions />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/services/multipleservices" element={<ServicesDropdown />} />
        <Route path="/it_services" element={<ItServices />} />
        <Route path="/staffing_services" element={<StaffingServices />} />




      </Routes> */}
    </HashRouter>
  );
}

export default App;
